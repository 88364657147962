import {createGlobalStyle} from "styled-components";

export const Styles = createGlobalStyle`

  @font-face {
    font-family: "CoHeadlineCorp";
    src: url("/fonts/CoHeadlineCorp-Light.ttf") format("truetype");
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "CoHeadlineCorp";
    src: url("/fonts/CoHeadlineCorp-Bold.ttf") format("truetype");
    font-style: normal;
    font-weight: bold;
    font-display: swap;
  }


  body,
  html,
  a {
    font-family: 'CoHeadlineCorp', sans-serif;
  }


  body {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    background: #fff;
    overflow-x: hidden;
  }

  a:hover {
    color: #ffffff;
  }

  input,
  textarea {
    border-radius: 4px;
    border: 0;
    background: rgb(241, 242, 243);
    transition: all 0.3s ease-in-out;
    outline: none;
    width: 100%;
    padding: 1rem 1.25rem;

    :focus-within {
      background: none;
      box-shadow: #B2D234 0px 0px 0px 1px;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Motiva Sans Bold', serif;
    color: #ffffff;
    font-size: 56px;
    line-height: 1.18;

    @media only screen and (max-width: 890px) {
      font-size: 47px;
    }

    @media only screen and (max-width: 414px) {
      font-size: 32px;
    }
  }

  p {
    color: #ffffff;
    font-size: 21px;
    line-height: 1.41;
  }

  h1 {
    font-weight: 600;
  }

  a {
    text-decoration: none;
    outline: none;
    color: #B2D234;

    :hover {
      color: #B2D234;
    }
  }

  *:focus {
    outline: none;
  }

  .about-block-image svg {
    text-align: center;
  }

  .ant-drawer-body {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding-top: 1.5rem;
  }

  .ant-drawer-content-wrapper {
    width: 300px !important;
  }
  
  #root {
    background: linear-gradient(160deg, #B2D234 6%, #fff 20%, #fff 60%) no-repeat;
    background-size: cover;

    @media only screen and (max-width: 757px) {
      background: linear-gradient(160deg, #B2D234 1%, #fff 10%, #fff 60%) no-repeat;
    }
  }
  
  .respo-padding {
    padding: 0;

    @media only screen and (max-width: 768px) {
      padding: 0 30px;
    }

    @media only screen and (max-width: 757px) {
      padding: 0;
    }
  }
`;
