import {useState} from "react";
import {Row, Col, Drawer} from "antd";
import {withTranslation} from "react-i18next";
import Container from "../../common/Container";
import {SvgIcon} from "../../common/SvgIcon";
import {Button} from "../../common/Button";
import {
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  Menu,
  CustomNavLinkSmall,
  Label,
  Outline,
  Span,
} from "./styles";

const Header = ({t}: any) => {
  const [visible, setVisibility] = useState(false);

  const showDrawer = () => {
    setVisibility(!visible);
  };

  const onClose = () => {
    setVisibility(!visible);
  };

  const MenuItem = () => {
    const scrollTo = (id: string) => {
      const element = document.getElementById(id) as HTMLDivElement;
      element.scrollIntoView({
        behavior: "smooth",
      });
      setVisibility(false);
    };

    const is_root = window.location.pathname == "/";

    return (
      <>
        {!is_root ? (
          <CustomNavLinkSmall onClick={() => window.location.href = '/'}>
            <Span>{t("Domov")}</Span>
          </CustomNavLinkSmall>
        ): ''}
        {is_root ? (
          <CustomNavLinkSmall onClick={() => scrollTo("about")}>
            <Span>{t("O nás")}</Span>
          </CustomNavLinkSmall>
        ):
          (
            <CustomNavLinkSmall onClick={() => window.location.href = '/#about'}>
              <Span>{t("O nás")}</Span>
            </CustomNavLinkSmall>
          )
        }
        <CustomNavLinkSmall onClick={() => window.location.href = '/pravidla'}>
          <Span>{t("Pravidlá")}</Span>
        </CustomNavLinkSmall>
        {/*<CustomNavLinkSmall onClick={() => scrollTo("mission")}>*/}
        {/*  <Span>{t("Mission")}</Span>*/}
        {/*</CustomNavLinkSmall>*/}
        {/*<CustomNavLinkSmall onClick={() => scrollTo("product")}>*/}
        {/*  <Span>{t("Product")}</Span>*/}
        {/*</CustomNavLinkSmall>*/}
        <CustomNavLinkSmall
          style={{width: "180px"}}
          onClick={() => scrollTo("contact")}
        >
          <Span>
            <Button>{t("Kontakt")}</Button>
          </Span>
        </CustomNavLinkSmall>
      </>
    );
  };

  return (
    <HeaderSection>
      <Container>
        <Row justify="space-between">
          <LogoContainer to="/" aria-label="homepage">
            {/*<SvgIcon src="logo.svg" width="101px" height="64px" />*/}
          </LogoContainer>
          <NotHidden>
            <MenuItem/>
          </NotHidden>
          <Burger onClick={showDrawer}>
            <Outline/>
          </Burger>
        </Row>
        <Drawer closable={false} visible={visible} onClose={onClose}>
          <Col style={{marginBottom: "2.5rem"}}>
            <Label onClick={onClose}>
              <Col span={12}>
                <Menu>Menu</Menu>
              </Col>
              <Col span={12}>
                <Outline/>
              </Col>
            </Label>
          </Col>
          <MenuItem/>
        </Drawer>
      </Container>
    </HeaderSection>
  );
};

export default withTranslation()(Header);
